// Estados: dev | prod | devNico
const appEnv = 'prod';

const url = {
    prod:   'https://fs-logistica.firebaseio.com',                  // produccion
    dev:    'https://logisticabyomi-default-rtdb.firebaseio.com',   // desarrollo
    devNico: 'https://n-logistica-default-rtdb.firebaseio.com',     // desarrollo Firebird Nico
}

const config = {
    prod: {
        apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",          // produccion
        authDomain: "fs-logistica.firebaseapp.com",                 // produccion
        databaseURL: "https://fs-logistica.firebaseio.com",         // produccion
        projectId: "fs-logistica",                                  // produccion
        storageBucket: "cantina-89c6a.appspot.com",                 // produccion
        messagingSenderId: "922828263476",                          // produccion
        appId: "1:922828263476:web:6694d23813f7c0aa9e3a61"          // produccion
    },

    dev: {
        apiKey: "AIzaSyALFHbu6uLUmvYODkXDfxfrNh8hu1kSqf0",                  // desarrollo
        authDomain: "logisticabyomi.firebaseapp.com",                       // desarrollo
        databaseURL: "https://logisticabyomi-default-rtdb.firebaseio.com",  // desarrollo
        projectId: "logisticabyomi",                                        // desarrollo
        storageBucket: "logisticabyomi.appspot.com",                        // desarrollo
        messagingSenderId: "988162009340",                                  // desarrollo
        appId: "1:988162009340:web:6cc07a01e4387692aa8f79",                 // desarrollo
        measurementId: "G-9J1WVQSWZV"                                       // desarrollo
    },

    devNico: {
        apiKey: "AIzaSyCzdAEI2JuSjMHRcL_JfyCoPVh4P7pcgck",
        authDomain: "n-logistica.firebaseapp.com",
        databaseURL: "https://n-logistica-default-rtdb.firebaseio.com",
        projectId: "n-logistica",
        storageBucket: "n-logistica.firebasestorage.app",
        messagingSenderId: "403191828532",
        appId: "1:403191828532:web:90233fb91cd5544fbffa61",
        measurementId: "G-NNPHELEFX4"
    },
}

export var firebaseConfig = config[appEnv];

export var firebaseLogin = {
    apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "https://aplicativos.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"
};

// Apis
export var api = {
  'choferes': `${url[appEnv]}/choferes.json`,
  'vehiculos': `${url[appEnv]}/vehiculos.json`,
  'empresas': `${url[appEnv]}/empresas.json`,
  'vinculos': `${url[appEnv]}/vinculos.json`,
  'irregulares': `${url[appEnv]}/irregulares.json`,
  'documentos': `${url[appEnv]}/documentos.json`,
  'rutas': `${url[appEnv]}/rutas.json`,
  'operaciones': `${url[appEnv]}/operaciones.json`,
  'rutas_ultimo_id': `${url[appEnv]}/rutas_ultimo_id.json`,
  'operacionesConsumo': "https://consumos.foodservice.com.ar/api/empresas"
}
// TODO apis mejoradas (Healthy tiene actualizaciones)
export function prefabQ(api,parametro){
    switch (api) {
        case 'chofer':
            return `${url[appEnv]}/choferes/${parametro}.json`
        case 'vehiculo':
            return `${url[appEnv]}/vehiculos/${parametro}.json`
        case 'empresa':
            return `${url[appEnv]}/empresas/${parametro}.json`
        case 'vinculos':
            return `${url[appEnv]}/vinculos/${parametro}.json`
        default:
            return "ninguna api encontrada"
    }
}

export function getEnvAPI(node){
    return `${url[appEnv]}/${node}.json`
}

export var tableConfig = {
    "language": {
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoPostFix": "",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "loadingRecords": "Cargando...",
        "lengthMenu": "Mostrar _MENU_ registros",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        },
        "processing": "Procesando...",
        "search": "Buscar:",
        "searchPlaceholder": "Término de búsqueda",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
    },
    "scrollX": false, 
    "search": {regex:true},
    dom: 'Bfrtip',
    responsive: false,
    nowrap: false,
    buttons: [
        'copy',
        'excel',
        'csv',
        'pdf'
    ]
};
